<template>
  <div id="wallet-address">
    <div id="wallet-icon-wrapper">
      <input
        :readonly="true"
        :value="wallet_address"
      >
      <copy-button
        id="copy-button"
        :text="wallet_address"
      />
    </div>
    <QrCode
      :wallet_address="wallet_address"
      style="position: absolute; right: 0"
    />
  </div>
</template>

<script>
import CopyButton from "@/components/UI/CopyButton.vue";
import QrCode from "@/components/QrCode.vue";

export default {
  name: "WalletAddress",
  components: { QrCode, CopyButton },
  // data() {
  //   return {
  //     wallet_address: "1JI2qw39D10sodO21",
  //   };
  // },
  props: ["wallet_address"],
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";
#wallet-address {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

#wallet-icon-wrapper{
  border: 1px solid $green-selected;
  border-radius: 5px;
  width: 100%;
  @media (min-width: 500px) {
    flex-grow: unset;
    width: calc(100% - 130px - 20px);
  }
}

input {
  flex-grow: 1;
  padding: 10px;
  width: 90%;

}
input:focus {
  outline: none;
  //border: 1px solid $green-selected;
  border: none;
}
input:hover {
  //border: 1px solid $green-selected;
  border: none
}

#copy-button#copy-button {
  //width: 24px;
  //height: 24px;
  position: absolute;
  top: 50%;
  right: 8px;
  --webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;

  @media (min-width: 500px) {
    right: calc(100% - 285px);
  }
}
</style>
