<template>
  <div id="payment-total">
    <div id="currency-block">
      <p class="label">
        {{ $t("currency") }}
      </p>
      <div id="currency-ico">
        <p
          id="currency-text"
          class="label"
        >
          USDT
        </p>
        <img
          id="tether-logo"
          :src="require('/src/assets/tether_logo.svg')"
          alt=""
        >
      </div>
    </div>
    <p class="text-key">
      {{ $t("warning") }}
    </p>

    <div class="line" />
    <div class="payment-item">
      <p class="text-value">
        {{ $t("total") }}
      </p>
      <div class="value-ico">
        <p class="text-value">
          {{ sum }} USDT
        </p>
        <copy-button :text="sum" />
        <!--        <p class="text-value">{{ sum + commission }} USDT</p>-->
        <!--        <copy-button :text="sum + commission" />-->
      </div>
    </div>
    <div id="exchange-rate">
      <p style="color: #27d38a; font-size: 10px; margin-right: 5px">
        ⬤
      </p>
      <p
        class="text-key"
        style="font-size: 13px"
      >
        {{ $t("exchange_rate") }}: {{ exchange_rate }} {{ currency }} ≈ 1 USDT
      </p>
    </div>
  </div>
</template>

<script>
import CopyButton from "@/components/UI/CopyButton.vue";

export default {
  name: "PaymentTotal",
  components: { CopyButton },
  // eslint-disable-next-line vue/require-prop-types
  props: ["sum", "commission", "currency", "exchange_rate"],
  methods: {
    copyToClipboard(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          console.log("Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy to clipboard", err);
        });
    },
    // handleClick(value) {
    //   console.log(value);
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

#payment-total {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: $grey-background;
}

.payment-item {
  display: flex;
  justify-content: space-between;
}

.text-value {
  font-size: 16px;
  //white-space: nowrap;
}

.text-key {
  font-size: 13px;
}

.value-ico {
  display: flex;
  align-items: center;
  gap: 10px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #bfbebe;
}

#exchange-rate {
  display: flex;
}

.ico {
  cursor: pointer;
}
</style>
