<template>
  <button class="network-item" :style="cssVars" @click="select">
    {{ data.network_name }}
  </button>
</template>

<script>
export default {
  name: "NetworkItem",
  props: ["data"],
  methods: {
    select() {
      this.$emit("selectNew", this.data.network_name);
    },
  },
  computed: {
    cssVars() {
      if (this.data.value === true) {
        return {
          "--background-color": "#27D38A",
        };
      }
      return {
        "--background-color": "#E6F9F1",
      };
    },
  },
};
</script>

<style scoped lang="scss">
//@import "../src/assets/variables.scss";
@import "@/assets/variables.scss";

.network-item {
  padding: 10px calc((100vw - 40px) * 0.03);
  background-color: var(--background-color);
  //background-color: $green-not-selected;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

//.network-item:hover{
//  background-color: $green-selected;
//}

@media (min-width: 500px) {
  .network-item {
    padding: 10px 18px;
  }
}

@media (max-width: 400px) {
  .network-item {
    //padding: 10px calc((100vw - 40px) * 0.03);
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  .network-item {
    padding: 10px calc((100vw - 40px) * 0.025);
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .network-item {
    padding: 10px calc((100vw - 40px) * 0.02);
    font-size: 14px;
  }
}
</style>
