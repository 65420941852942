import { createStore } from "vuex";
import axios from "axios";
import router from '../router/index.js';

// const backLink = "http://0.0.0.0:8080/"
// const backLink = "http://192.168.82.222:8083/"
const backLink = "https://api.whatsapay.com/"

export default createStore({
  state: {
    is_copied_pop_up_visible: false,
    order_object: null
  },
  getters: {
    GET_COPIED_POPUP_STATE(state){
      return state.is_copied_pop_up_visible
    },
    GET_ORDER_OBJECT(state){
      return state.order_object
    }
  },
  mutations: {
    SET_COPIED_POPUP: (state) => {
      state.is_copied_pop_up_visible = true
      setTimeout(() => {
        state.is_copied_pop_up_visible = false
      }, 2000);
    },
    SET_ORDER_OBJECT: (state, object) => {
      state.order_object = object
      console.log(object)

    }
  },
  actions: {
    SHOW_COPIED_POPUP({commit}){
      commit('SET_COPIED_POPUP')
    },
    // eslint-disable-next-line no-unused-vars
    async GET_ORDER_BY_ID({commit}, data){
      console.log(data)
      return await axios.get(
          backLink + 'db/get_one_invoice/?order_id=' + data.order_id + '&client_id=' + data.client_id,
          {
            headers: {
              "Authorization": "Bearer " + data.token
            }
          }
      )
    },
    // eslint-disable-next-line no-unused-vars
    // async CHECK_PAYMENT_STATUS({commit}, data){
    //   console.log(data)
    //   return axios.get(
    //       (`${backLink}db/check_is_order_paid/?order_id=${data.order_id}&client_id=${data.client_id}`),
    //       {
    //         headers: {
    //           "Authorization": "Bearer " + data.token
    //         }
    //       }
    //   )
    // eslint-disable-next-line no-unused-vars
    async CHECK_PAYMENT_STATUS({commit}, data){

      console.log(data)
      axios.get(
          (`${backLink}db/check_is_order_paid/?order_id=${data.order_id}&client_id=${data.client_id}&store_id=${data.store_id}`),
          {
            headers: {
              "Authorization": "Bearer " + data.token
            }
          }
      )
          .then(response => {
        console.log(response.data)
        if (response.data.status === 'Paid'){
          router.push('/success/?' + data.uri)
        }
        if (response.data.status === 'Expired'){
          router.push('/expired/?' + data.uri)
        }
        if (response.data.status === 'Not Paid'){
          router.push('/payment/?' + data.uri)
          // Not Paid but not expired
        }
        if(data.is_apple){
          let apple_order_object = {
            ...response.data.order_object,
            actual_exchange_rate: response.data.order_object.actual_exchange_rate.toLocaleString('de-DE', { minimumFractionDigits: 2 }),
            total: response.data.order_object.total.toLocaleString('de-DE', { minimumFractionDigits: 2 }),
            total_usd: response.data.order_object.total_usd.toLocaleString('de-DE', { minimumFractionDigits: 4 })
          }
          commit('SET_ORDER_OBJECT', apple_order_object)
        }else{
          commit('SET_ORDER_OBJECT', response.data.order_object)
        }
      })
    }
  },
  modules: {},
});

