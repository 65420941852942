<template>
  <img
    :src="require('/src/assets/copy_ico.svg')"
    alt=""
    class="ico"
    @click="copyToClipboard(text)"
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CopyButton",
  props: ["text"],
  methods: {
    ...mapActions(["SHOW_COPIED_POPUP"]),
    copyToClipboard(value) {
      console.log(value);
      navigator.clipboard.writeText(value);
      this.SHOW_COPIED_POPUP();
    },
  },
};
</script>

<style scoped></style>
