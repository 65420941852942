<template>
  <div id="order-info">
    <div class="first-row">
      <!--      <img-->
      <!--        id="logo"-->
      <!--        :src="require('/src/assets/Logo.svg')"-->
      <!--        alt=""-->
      <!--      >-->
      <!--      src="https://whatsapay.s3.eu-central-1.amazonaws.com/logos/behancebot_logo.svg"-->
      <!--      -->
      <a
        :href="order_object.store_link"
        target="_blank"
      >
        <img
          :src="order_object.logo_link"
          style="height: 40px; border-radius: 5px"
          alt="client image"
        >
      </a>

      <div class="order-total">
        <div class="order-total-item">
          <p class="text-key">
            {{ $t("order") }}
          </p>
          <p class="text-value">
            {{ order_object.order_id }}
          </p>
        </div>
        <div class="order-total-item">
          <p class="text-key">
            {{ $t("total") }}
          </p>
          <p class="text-value">
            {{ order_object.total }} {{ order_object.currency }}
          </p>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div
        v-if="Object.values(order_object.products).length === 1"
        id="purpose-for-one"
      >
        <p class="text-value">
          {{ $t("purpose_of_payment") }}
        </p>
        <p class="text-value">
          {{ order_object.products[0].product_name }}
        </p>
      </div>
      <div
        v-else
        id="purpose-for-more"
      >
        <div id="purpose-header">
          <!--          <p class="text-value">Purpose of payment</p>-->
          <!--          <p class="text-value">List of products</p>-->
          <p class="text-value">
            {{ order_object.products[0].product_name }}
          </p>
          <p class="text-value">
            {{ order_object.products[0].price }} {{ order_object.currency }}
          </p>
        </div>
        <div
          v-if="!is_expanded"
          class="see-all"
          @click="expand_collapse"
        >
          <p class="text-value">
            See all
          </p>
          <img
            :src="require('/src/assets/chevron-down.svg')"
            alt="chevron_down"
          >
        </div>
        <div
          v-if="is_expanded"
          class="see-all"
          @click="expand_collapse"
        >
          <p class="text-value">
            Close
          </p>
          <img
            style="transform: rotate(180deg)"
            :src="require('/src/assets/chevron-down.svg')"
            alt="chevron_down"
          >
        </div>
        <v-expand-transition>
          <div
            v-if="is_expanded"
            class="transition-block"
          >
            <div id="list-of-products">
              <div
                v-for="(value, key) in order_object.products.slice(1)"
                :key="key"
                class="product"
              >
                <p class="text-value">
                  {{ value.product_name }}
                </p>
                <p class="text-value">
                  {{ value.price }} UAH
                </p>
              </div>
            </div>
          </div>
        </v-expand-transition>
        <!--        </transition>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderInfo",
  props: ["order_object"],
  data() {
    return {
      is_expanded: false,
      isFirstLoad: true,
    };
  },
mounted() {
  const expireAt = new Date(this.order_object.expire_at);
  const currentTime = new Date();
  const timezoneOffset = currentTime.getTimezoneOffset();
  expireAt.setMinutes(expireAt.getMinutes() - timezoneOffset);
  
  let initialURL; // Объявление переменной initialURL

  if (window.location.href.includes('success')) {
  return;
  }
  if (window.location.href.includes('expired')) {
  return;
  }
        // Сохраняем первоначальный URL
        initialURL = window.location.href;
        if(this.isFirstLoad) {
        setTimeout(() => {
          // Перенаправление на первоначальный URL
          window.location.href = initialURL;
        }, 60000);
        }
}, 
  methods: {
    expand_collapse() {
      this.is_expanded = !this.is_expanded;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

#order-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: $grey-background;
  border-radius: 5px;
}

.first-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .order-total-item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}

.second-row {
  #purpose-for-one,
  #purpose-header {
    display: flex;
    justify-content: space-between;
  }

  .text-value {
    text-align: start;
  }

  .see-all {
    display: flex;
    justify-content: center;
    cursor: pointer;

    .text-value {
      font-size: 13px;
    }
  }

  .transition-block {
    position: absolute;
    width: calc(100vw - 32px);
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    background-color: $grey-background;
    border-radius: 8px;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 1000;
    scrollbar-gutter: stable both-edges;

    @media (min-width: 500px) {
      width: 452px;
    }
  }

  #list-of-products {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;

    .product {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
