<template>
  <div id="copied-pop-up">
    <!--    <slot></slot>-->
    <p>Copied!</p>
    <!--    <p>&nbsp;was copied!</p>-->
  </div>
</template>

<script>
export default {
  name: "CopiedPopUp",
};
</script>

<style scoped lang="scss">
#copied-pop-up {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 32px;
  background-color: #323232;
  border-radius: 5px;
  //cursor: pointer;
  // Shadow
  //-webkit-box-shadow: 0 13px 34px -19px rgba(50, 50, 50, 1);
  //-moz-box-shadow: 0 13px 34px -19px rgba(50, 50, 50, 1);
  box-shadow: 0 4px 16px -8px rgba(50, 50, 50, 1);

  // Font
  color: #fff0ff;
  font-size: 13px;
  position: absolute;
  top: 10%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}
</style>
