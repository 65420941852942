<template>
  <footer>
    <div id="powered-by">
      <!--      <p class="text-key">-->
      <!--        Powered by-->
      <!--      </p>-->
      <a
        href="https://whatsapay.com"
        target="_blank"
      >
        <!--        <img-->
        <!--          :src="require('/src/assets/our_logo.svg')"-->
        <!--          alt="our logo"-->
        <!--          style="height: 26px"-->
        <!--        >-->
        <!--        <img-->
        <!--          src="https://whatsapay.s3.eu-central-1.amazonaws.com/logos/whatsapay_lg.svg"-->
        <!--          alt="Whatsapay logo"-->
        <!--          style="height: 26px"-->
        <!--        >-->
        <img
          :src="require('/src/assets/whatsapay_large_logo.svg')"
          alt="our logo"
          style="height: 26px"
        >
      </a>
    </div>
    <!--    <a href="#">{{ $t("terms_policy") }}</a>-->
    <div style="display: flex; gap: 8px">
      <p
        class="text-key"
        style="align-self: center"
      >
        {{ $t("connection_questions") }}
      </p>
      <a
        href="https://t.me/WhatsaPay"
        target="_blank"
        style="height: 26px"
      >
        <img
          :src="require('/src/assets/telegram.svg')"
          alt="telegram_logo"
          style="height: 26px; align-self: center"
        >
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PoweredBy"
}
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

#powered-by {
  display: flex;
  align-items: center;
  gap: 5px;

p {
  font-size: 13px;
}

// Link for image
   a {
     max-height: 26px;
   }
}

a {
  color: $green-selected;
  font-size: 14px;
}
}
</style>