<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  // data() {
  //   return {
  //     isConnected: false,
  //     socketMessage: ''
  //   }
  // },
  //
  // sockets: {
  //   connect() {
  //     // Fired when the socket connects.
  //     this.isConnected = true;
  //   },
  //
  //   disconnect() {
  //     this.isConnected = false;
  //   },
  //
  //   // Fired when the server sends something on the "messageChannel" channel.
  //   messageChannel(data) {
  //     this.socketMessage = data
  //   }
  // },
  methods: {
    ...mapActions(["CHECK_PAYMENT_STATUS"]),
  },
  mounted() {

    let uri = window.location.href.split("?");
    const params = uri[1].split("&");
    const check_payment = {
      order_id: params[0].split("=")[1],
      client_id: params[1].split("=")[1],
      store_id: params[2].split("=")[1],
      token: params[3].split("=")[1],
      uri: uri[1],
      is_apple: /Mac|iPad|iPhone|iPod/.test(navigator.userAgent)
    };

    this.CHECK_PAYMENT_STATUS(check_payment);
    // this.check(check_payment);
    this.$i18n.locale = params[4].split("=")[1];
    console.log(
      params[0].split("=")[1],
      params[1].split("=")[1],
      params[2].split("=")[1],
      params[3].split("=")[1]
    );
  },
};
</script>

<style lang="scss"></style>
