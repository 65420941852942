<template>
  <div id="main-page">
    <back-ground />
    <div id="main-view" >
      <OrderInfo :order_object="order_object"/>
      <div id="success-block">
        <img :src="require('@/assets/tick_success.svg')" alt="success_image">
        <p id="success-text">Payment Successful!</p>
      </div>
      <PoweredBy/>
    </div>
  </div>
</template>

<script>
import BackGround from "@/components/BackGround.vue";
// import OrderInfo from "@/components/OrderInfo.vue";
import {mapGetters} from "vuex";
import OrderInfo from "@/components/OrderInfo.vue";
import PoweredBy from "@/components/PoweredBy.vue";

export default {
  name: "SuccessPage",
  components: {
    PoweredBy,
    OrderInfo,
    // OrderInfo,
    BackGround},
  computed:{
    ...mapGetters({
      order_object: "GET_ORDER_OBJECT",
    }),
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

#main-view{
  display: flex;
  justify-content: space-between;
  //justify-content: center;
  align-items: center;

}

#success-block{
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  img{
    height: 60px;
    width: 60px;
  }

  #success-text{
    text-align: right;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    color: $green-selected;
  }
}


// TODO: DODELAT CRINGE

#order-info{
  width: 100%;
}

footer{
  width: 100%;
}

</style>