<template>
  <div id="network-select">
    <NetworkItem
      v-for="(value, key) in networks"
      :key="key"
      :data="{ network_name: key, value: value }"
      @selectNew="select_new_network"
    />
  </div>
</template>

<script>
import NetworkItem from "@/components/NetworkItem.vue";

export default {
  name: "NetworkSelect",
  data() {
    return {
      networks: {
        Solana: false,
        BEP20: false,
        // TRC20: false,
        // ERC20: false,
        // EOS: false,
      },
    };
  },
  methods: {
    select_new_network(name) {
      Object.keys(this.networks).map((key) => (this.networks[key] = false));
      this.networks[name] = true;
      this.$emit("selectNew", name);
    },
  },
  components: {
    NetworkItem,
  },
  props: ['networks_list'],
  mounted() {
    console.log(this.networks)
    this.networks = {}
    console.log(this.networks)
    this.networks_list.forEach(network => {
      this.networks[network] = false;
    })
    console.log(this.networks)
  }
};
</script>

<style scoped lang="scss">
//@import "../src/assets/variables.scss";
@import "@/assets/variables.scss";

#network-select {
  width: 100%;
  display: flex;
  background-color: $green-not-selected;
  justify-content: space-between;
  border-radius: 5px;
}
</style>
