import { createApp } from "vue";
import App from "./App.vue";
import {createI18n} from 'vue-i18n'
import store from "./store";
import router from './router/index.js'


const messages = {
  en: require('./locales/en.json'),
  ua: require('./locales/ua.json'),
  ru: require('./locales/ru.json')
}

const i18_n = new createI18n({
  locale: 'en', // set default locale
  messages
})

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


const vuetify = createVuetify({
  components,
  directives,
});

//import socketio from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
//const uri = window.location.href.split("?")
//const params = uri[1].split("&");

//console.log(uri)
//export const SocketInstance = socketio(`http://31.220.94.49:5000/?socket_id=${params[1].split("=")[1]}-${params[2].split("=")[1]}-${params[0].split("=")[1]}`);
// listen for event

//SocketInstance.on('notification', (data) => {
//  console.log(data)
//  if (data.status === 'success'){
//    router.push('/success/?' + uri)
//  }
//  if (data.status === 'expire'){
//    router.push('/expired/?' + uri)
//  }
//  SocketInstance.disconnect()
//});

// .use(VueSocketIO, SocketInstance)

createApp(App).use(i18_n).use(vuetify).use(store).use(router).mount("#app");
