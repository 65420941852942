<template>
  <div id="main-page">
    <back-ground />
    <div
      v-if="order_object !== null"
      id="main-view"
    >
      <header>
        <OrderInfo :order_object="order_object" />
        <!--              <OrderInfo :order_object="order_obj" v-if="order_object"/>-->
      </header>
      <div id="main">
        <div id="content">
          <div
            id="network-block"
            class="block"
          >
            <p class="label">
              {{ $t("network") }}
            </p>
            <NetworkSelect
              :networks_list="Object.keys(order_object.networks)"
              @selectNew="changeWalletAddress"
            />
          </div>
          <div
            id="wallet-block"
            class="block"
          >
            <p class="label">
              {{ $t("wallet_address") }}
            </p>
            <WalletAddress :wallet_address="wallet_address" />
          </div>
          <div
            id="payment-block"
            class="block"
          >
            <p class="label">
              {{ $t("payment") }}
            </p>
            <payment-total
              :sum="order_object.total_usd"
              :commission="1"
              :exchange_rate="order_object.actual_exchange_rate"
              :currency="order_object.currency"
            />
          </div>
        </div>
        <PoweredBy />
      </div>
    </div>

    <transition name="fade">
      <copied-pop-up v-show="is_coped_popup_visible" />
    </transition>
  </div>
</template>

<script>
import BackGround from "@/components/BackGround.vue";
import WalletAddress from "@/components/WalletAddress.vue";
import CopiedPopUp from "@/components/CopiedPopUp.vue";
import PaymentTotal from "@/components/PaymentTotal.vue";
import NetworkSelect from "@/components/NetworkSelect.vue";
import OrderInfo from "@/components/OrderInfo.vue";
import { mapActions, mapGetters } from "vuex";
import PoweredBy from "@/components/PoweredBy.vue";

export default {
  name: "MainPage",
  components: {
    PoweredBy,
    // QrCode,
    BackGround,
    WalletAddress,
    CopiedPopUp,
    PaymentTotal,
    NetworkSelect,
    OrderInfo,
  },
  data() {
    return {
      // order_object: null,
      wallet_address: "",
      networks: null,
    };
  },
  computed: {
    ...mapGetters({
      is_coped_popup_visible: "GET_COPIED_POPUP_STATE",
      order_object: "GET_ORDER_OBJECT",
    }),
    // order_obj() {
    //   return this.order_object;
    // },
  },
  methods: {
    ...mapActions(["GET_ORDER_BY_ID"]),
    // async sendRequest(order_id, client_id, token) {
    //   try {
    //     const data = (await this.GET_ORDER_BY_ID({
    //       order_id: order_id,
    //       client_id: client_id,
    //       token: token
    //     })).data;
    //     console.log(data)
    //     this.order_object = {
    //       total: data.total ,
    //       order_id: "#" + data.order_id,
    //       products: data.purpose
    //     };
    //     this.networks = data.networks;
    //     console.log(this.order_object);
    //   } catch (e) {
    //     alert(e);
    //     console.log(e);
    //   }
    // },
    changeWalletAddress(network_name) {
      this.wallet_address = this.order_object.networks[network_name];
    },
  },
  mounted() {
    // let uri = window.location.href.split("?");
    // const params = uri[1].split("&");
    // this.sendRequest(params[0].split('=')[1], params[1].split('=')[1], params[2].split('=')[1]);
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;800&display=swap");
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $grey-background;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: $green-selected;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: $green-selected;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html {
  height: 100%;
}

#main-page {
  font-family: Manrope, sans-serif;
  color: $dark-primary;
  padding: 0;
  margin: 0;
  //height: 100%;
  background-color: #27d38a;

  input {
    font-family: Manrope, sans-serif;
  }

  p {
    margin: 0;
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

#main-view {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 16px;
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  #currency-block {
    display: flex;
    justify-content: space-between;
    #currency-ico {
      display: flex;
      align-items: center;
      gap: 10px;

      #currency-text {
        font-weight: 500;
      }

      #tether-logo {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.text-key {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $grey-text;
}

.text-value {
  text-align: right;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #1e1e1e;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}


@media only screen and (max-height: 730px) and (min-width: 600px) {
  .background.background {
    height: 100%;
  }
  #main-view {
    //margin-top: 3%;
    //max-height: 100%;
    max-height: 96%;
  }
}

@media only screen and (max-height: 700px) and (min-width: 600px) {
  .background.background {
    height: 120vh;
  }
  #main-view {
    margin-top: 5%;
    //max-height: 100%;
    //max-height: 100%;
    height: 110vh;
    max-height: 110vh;
  }
}

@media screen and (min-height: 731px) {
  #main-view {
    max-height: 90%;
  }
}

@media (min-width: 500px) {
  #main-view {
    //max-height: 90%;
    border-radius: 16px;
    padding: 24px;
  }

  #order-info#order-info,
  #payment-total#payment-total {
    border-radius: 12px;
  }

  #network-select#network-select,
  .network-item.network-item,
  #wallet-address input {
    border-radius: 8px;
  }

  #wallet-address {
    input {
      padding: 10px 14px;
    }

    //#copy-button#copy-button{
    //  right: 14px;
    //}
  }
}
</style>
