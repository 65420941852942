<template>
  <div class="background">

  </div>
</template>

<script>
export default {
  name: "BackGround",
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

body {
  margin: 0;
  overflow: hidden;
}

.background {
  width: 100vw;
  height: 100%;
  //height: 100vh;
  background: #283957;
  position: absolute;
  overflow: hidden;

  @media (max-width: 500px) {
    display: none;
  }

  //@media only screen and (max-height: 750px) {
  //  height: 120vh;
  //}
  //@media only screen and (min-width: 1600px) {
  //  height: 100vh;
  //}
}

$particleSize: 20vmin;
$animationDuration: 60s;
$amount: 1;
.background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (#27d38a, #5aafe4,); //#FFACAC);
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 3) * $particleSize * 0.5;
      //$blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}
</style>
