<template>
  <div id="qr-code">
<!--    :image="require('/src/assets/our_mini_logo.svg')"-->

    <QRCodeVue3
      :width="130"
      :height="130"
      :image="require('/src/assets/whatsapay_sm.svg')"
      v-bind:value="wallet_address"
      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 5 }"
      :dotsOptions="{
        type: 'rounded',
        // color: '#26249a',
        color: '#2e9d8e',
        gradient: {
          type: 'linear',
          rotation: 45,
          colorStops: [
            { offset: 0, color: '#24719a' },
            { offset: 1, color: '#27D38A' },
          ],
        },
      }"
      :backgroundOptions="{ color: '#ffffff' }"
      :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
      :cornersDotOptions="{ type: undefined, color: '#000000' }"
      fileExt="png"
      :download="false"
      myclass="my-qur"
      imgclass="img-qr"
      downloadButton="my-button"
      :downloadOptions="{ name: 'vqr', extension: 'png' }"
    />
  </div>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: "QrCode",
  components: { QRCodeVue3 },
  props: ["wallet_address"],
};
</script>

<style scoped lang="scss">
.my-qur + img {
  border-radius: 8px;
}

#qr-code {
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
}
</style>
